<template>
    <div  class="answersTable" >
        <table v-if="data" id="answersTable" class="table" :data-float-status="floatStatusBar" v-on:scroll.passive="toggleStatusField">
            <thead>
                <tr class="row">
                    <th class="col index">#</th>
                    <th class="col status"></th>
                    <th v-for="(question, index) in questions" :key="question.slug" class="col">{{index + 1}}.{{questionTitleWithVariables(question.value)}}</th>
                    
                    <!-- UTMS -->
                    <th v-if="showUtms" class="col">utm_source</th>
                    <th v-if="showUtms" class="col">utm_medium</th>
                    <th v-if="showUtms" class="col">utm_campaign</th>
                    <th v-if="showUtms" class="col">utm_term</th>
                    <th v-if="showUtms" class="col">utm_content</th>
                    
                    <!-- Scores -->
                    <th v-if="form.options.scores.active" class="col">Pontos</th>
                    
                    <!-- Created at -->
                    <th class="col">Data</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in rows" :key="row.respondent_id" :data-respondent-id="row.public_user_id" :data-status="getCustomFields(row.custom_fields)" class="row">
                    <td class="col cell index">{{data.total_respondents - rowIndex}}</td>
                    <td class="col cell status">
                        <div>
                            <SelectRowStatus :respondent="row.respondent_id" v-model="row.custom_fields[0]" />
                        </div>
                    </td>
                    <td v-for="(answer, index) in row.answers" :key="row.respondent_id + index" class="col cell question-col">
                        <span v-if="answer" :data-more="hasMore(answer.value)">

                            <!-- Date -->
                            <span v-if="answer.field_type == 'date'">{{formatAnswerAsDate(answer.value)}}</span>
                            
                            <!-- Phone -->
                            <span v-if="answer.field_type == 'cnpj'">
                                {{formatAnswerText(answer)}} <Btn @click.native="trackEvent('Feature: CNPJ Shortcut')" :href="'https://cnpjs.rocks/cnpj/' + answer.value" layout="icon" size="sm"><Icon name='search' /></Btn>
                            </span>

                            <!-- Phone -->
                            <span v-if="answer.field_type == 'phone'">
                                {{formatAnswerText(answer)}} <Btn :href="'https://api.whatsapp.com/send?phone='+ formatAnswerAsFullPhone(answer.value)" @click.native="trackEvent('Feature: CNPJ Shortcut')" layout='icon' class="whatsapp-button" size="sm"><Icon name='whatsapp' type="brand" /></Btn>
                            </span>
                            
                            <!-- Radio -->
                            <span v-if="answer.field_type == 'radio'">{{toString(answer.value)}}</span>
                            
                            <!-- Radio Image Field -->
                            <div v-if="answer.field_type == 'imageradio'" class="imageradio-container">
                                <span v-for="answer in toObject(answer.value)" :key="answer.key" class="imageradio-answer">                                    
                                    <div v-tooltip="answer.label" class="imageradio-preview"><img v-if="answer.image" :src="$bucket + answer.image.path" class="imageradio-img" alt=""> <span class="imageradio-label">{{answer.label}}</span></div>
                                </span>
                            </div>

                            <!-- Upload Field -->
                            <div v-else-if="answer.field_type == 'upload'">
                                <div @click="toggleFileList(answer.field_slug + '-' + rowIndex)" class="files-list-container-header mb8">                                    
                                    <Icon name="file" />
                                    <span class="file-list-label ml8">{{toObject(answer.value).length}} arquivos anexos.</span>
                                    <Btn  class="file-show-btn" size="sm" layout="icon"><Icon name="plus" /></Btn>                                                                         
                                </div>

                                <div class="files-list-container" :ref="answer.field_slug + '-' + rowIndex" >
                                    <a v-for="item in toObject(answer.value)" :key="item.path" :href="$bucket + item.path" target="_blank" class="file-item mr8 mb8"  >
                                        <Icon name="file" class="mr4" /> {{limit(item.filename, 25)}}
                                    </a>                                
                                </div>
                            </div>

                            <!-- Regular answer -->
                            <!-- <span v-if="regularAnwser(answer)">{{limit(answer.value, 35)}}</span> -->
                            <span v-if="regularAnwser(answer)">{{limit(formatAnswerText(answer), 35)}}</span>
                            <span v-if="hasMore(answer.value)" class="plus"><Icon name="plus" /></span>
                            <div v-if="hasMore(answer.value)" class="more-content">{{formatAnswerText(answer)}}</div>
                        </span>
                        <span v-else class="no-answer">Não respondeu...</span>

                    </td>
                    
                    <!-- UTMS -->
                    <td v-if="showUtms" class="col cell">{{row.utm_source}}</td>
                    <td v-if="showUtms" class="col cell">{{row.utm_medium}}</td>
                    <td v-if="showUtms" class="col cell">{{row.utm_campaign}}</td>
                    <td v-if="showUtms" class="col cell">{{row.utm_term}}</td>
                    <td v-if="showUtms" class="col cell">{{row.utm_content}}</td>

                    <!-- Scores -->
                    <td v-if="form.options.scores.active" class="col cell">✨ {{row.total_score}}</td>
                    
                    <!-- Created at -->
                    <td class="col cell"><span v-tooltip="formatDate(row.created_at)">{{formatDate(row.created_at, 'ago')}}</span></td>
                </tr>
            </tbody>
        </table>

        <div class="load-more">
            <Btn class="load-more-btn" type="outline" v-if="data.respondents.length != data.total_respondents" @click.native="loadMoreAnswers" :loading="working.loadAnswers">Carregar mais respostas</Btn>
        </div>

    </div>

</template>

<script>
import SelectRowStatus from './form/SelectRowStatus.vue'
import Btn from './form/Btn.vue'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
    components: { Btn, SelectRowStatus },
        name: "AnswersTable",
        mixins: [UtilitiesMixin],
        props: {
            data: Object,
            form: Object
        },

        data() {
            return {
                questions: null,
                rows: null,
                floatStatusBar: false,
                showUtms: this.form.options.utms.active,
                working: {
                    loadAnswers: false
                }
            }
        },
        mounted () {
            this.questions = this.getQuestions(this.form.fields)
            this.rows = this.formatAsTable()
        },  
        
        watch: {
            data(value) {
                this.questions = this.getQuestions(this.form.fields)
                this.rows = this.formatAsTable()                
            }
        },

        methods: { 
            
            toggleStatusField(){
                const table = document.getElementById('answersTable')
                const position = table.scrollLeft
                if(position > 100) this.floatStatusBar = true
                else this.floatStatusBar = false
            },

            getCustomFields(customFields){
                if(customFields.length == 0) return null
                else return customFields[0].value[0]
            },

            trackEvent(eventName){
                this.$trackEvent.mixpanel(eventName, {distinct_id: this.form.cus, form_id: this.form.slug});
            },

            loadMoreAnswers(){
                this.working.loadAnswers = true
                this.$parent.loadMoreAnswers()
                .then(result => {
                    this.rows = this.formatAsTable()
                    this.working.loadAnswers = false
                })

            },

            regularAnwser(answer) {
                const special = ["date", "phone", "radio", "upload", "cnpj", "imageradio"]
                if(special.includes(answer.field_type)) return false
                return true
            },
            
            toggleFileList(event){
                this.$refs[event][0].toggleAttribute("data-show")
            },



            hasMore(value){
                if(this.stringIsJson(value)){
                    const json = JSON.parse(value)                    
                    if(json.phone) return false // Gambiarra para phone field. Corrigir depois
                    if(json.country) return true // Gambiarra para addrres field. Corrigir depois
                    if(json.length > 20) return true
                }
                
                if(!this.stringIsJson(value)){
                    if(value.length > 35) return true
                }
                
                return false
            },

            formatAsTable() {

                const questions = this.getQuestions(this.form.fields)
                const rows = getAnswersByRow(this.data.respondents)                        
                return rows

                function getAnswersByRow(respondents){
                        
                    let arrOfRespondents = []                        
                    respondents.forEach(respondent => {
            
                        let arrOfAnswers = [];     
                        questions.forEach((question) =>{ 
                            const answerObj = getAnwserForThisColumn(question.slug, respondent.answers)
                            arrOfAnswers.push(answerObj)
                        })

                        const data = {
                            answers: arrOfAnswers,
                            respondent_id: respondent.respondent_id,
                            custom_fields: respondent.custom_fields,
                            total_score: respondent.total_score,
                            utm_source: respondent.utm_source,
                            utm_medium: respondent.utm_medium,
                            utm_campaign: respondent.utm_campaign,
                            utm_term: respondent.utm_term,
                            utm_content: respondent.utm_content,
                            created_at: respondent.created_at,
                            completed_at: respondent.completed_at
                        }

                        arrOfRespondents.push(data)
                    
                    })

                    return arrOfRespondents

                }


                function getAnwserForThisColumn(column, answers){
                    let test = answers.filter(answer => {
                        return answer.field_slug == column
                    })

                    if(test[0] === undefined ) return null
                    return test[0]
                }

 
            },

            getQuestions(fields){
                const questions = fields.filter(question => {
                        const remove = ['welcome', 'thankyou', 'message']
                        return !remove.includes(question.type)
                    })
                return questions
            }
        },
    }
</script>

<style lang="sass" scoped>
.imageradio-container
    display: flex
    align-items: center

    .imageradio-answer
        display: flex
        align-items: center
        margin-right: $mgXs
        position: relative
        z-index: 0

        .imageradio-img
            background: $cG1
            padding: $mgXxs
            border-radius: 4px
            max-width: 30px
            margin-right: $mgXxs

        .imageradio-label
            display: none

    .imageradio-thumb
        width: 200px
        height: 200px
        position: absolute
        top: 0
        left: 0
        z-index: 200
        background: #fff
        border: 1px solid $cG3
        border-radius: 5px
        display: none
        align-items: center
        justify-content: center
        

        .imageradio-img
            max-width: 190px
            max-height: 190px
            object-fit: contain

            

.whatsapp-button::v-deep .btn
    color: #25D366
    font-size: $fzSm

    &:hover
        color: #25D366
        background-color: rgba(#25D366, .15)


.load-more
    display: flex
    align-items: center
    justify-content: center
    position: fixed
    bottom: $mg
    // background: #fff
    z-index: 50
    width: 100%

    .load-more-btn::v-deep .btn
        background: #fff



.files-list-container-header
    display: flex
    align-items: center
    cursor: pointer

    .file-show-btn
        margin-left: auto

.files-list-container
    display: none
    
    &[data-show]
        display: block

.file-item
    display: inline-flex
    font-size: $fzXs
    color: $cG4
    padding: $mgXxs $mgXs
    border: 1px solid $cG4
    border-radius: 4px
    margin-right: $mgXxs

    &:hover
        cursor: pointer
        @include glassStyle($cPrimary)

.answersTable
    max-width: 100%
    height: 100%
    background: #fff
    width: 100%

    .header


.table   
    font-size: $fzXs
    max-width: 100%
    width: 100%
    overflow: auto
    display: block
    height: 100%
    padding-bottom: $mgLg

    thead
        .row
            background: $cG1

        .col
            font-weight: normal
            color: $cG5

            &:hover
                background: $cG1

            &.status
                background: $cG1
                width: 35px



    .col        
        background: transparent
        width: 320px
        padding: $mgSm
        z-index: 0

        &:hover
            color: $cBrand          

        &.index
            background: transparent
            width: 50px
                    
        &.status
            width: 35px
            position: sticky
            // display: block
            left: 0px       
            z-index: 50 
            background: #fff
        

    
    .row
        display: flex
        border: 0
        border-bottom: 1px solid $cG1
        min-width: 100%
        text-align: left
        transition: all .5s
        // position: relative
        z-index: 0

        &:hover
            background: $cG1
            transition: all .5s

            .status
                background: $cG1

        &[data-status='success']
            background: rgba($cPositive, .07)



    .cell
        position: relative
        z-index: 0

    .no-answer
        font-style: italic
        color: $cG3

    .more-content
        padding: $mgSm
        position: absolute
        width: 90%
        bottom: -16px
        right: 0
        background: #fff
        z-index: 50
        box-shadow: 0 0 8px rgba(0,0,0, .15)
        display: none
        font-size: $fzXxs
        line-height: 120%
        max-height: 100px
        overflow: auto
        border-radius: 3px

        &:hover
            display: block

[data-float-status="true"]
    td.col.status
        // position: sticky
        left: 0
        background: #fff
        z-index: 50
        width: 45px
        transition: all .3s        
        // box-shadow: 3px 3px 12px rgba(0,0,0, .1)

    tbody tr.row
        margin-left: -10px

    .row:hover
        td.col.status
            z-index: 50
            background: $cG1

    
[data-more='true']
    .plus
        cursor: pointer
        margin-left: 8px
        padding: 2px 4px
        border: 1px solid $cPrimary
        border-radius: 3px
        color: $cPrimary
        font-size: 11px
        

        &:hover
            parent-selector:

            + .more-content 
                display: block

</style>