export const AnswersMixin = {
    methods: {   

        updateCustomField(event, respondent_id){                
            let url
            if(event.id){
                url = process.env.VUE_APP_API_URL + 'customfield/' + event.id
            } 
            else{
                url = process.env.VUE_APP_API_URL + 'customfield'
                event.respondent_id = respondent_id
            }                
        
            return this.$axios
                .post(url, event)
                .then(response => {
                    return response
                })
        },
        
        deleteCustomField(id){
            const url = process.env.VUE_APP_API_URL + 'customfield/' + id
            return this.$axios                
                .delete(url)
                .then(response => {
                    return response
                })        
        }

    },

}
