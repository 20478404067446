<template>
  <div v-if="answersList && form" class="editor-answers" >
      
    <TopHeader nav="Editor" :data="form"> 
      <div v-if="!isNew" class="layout-style mr16">
        <div class="layout-title mr8">Tabela</div>
        <SwitchField @click.native="useLayoutTable" :active="layoutTable"  />
      </div>
      <Btn v-popover:filters v-if="!isNew" type="secondary" size="xs">Filtros <span v-if="filters.completed" class="filters-count">(1)</span></Btn>
      <Btn @click.native="openExportModal" v-if="!isNew" type="secondary" size="xs"><Icon name="cloud-download-alt" /> Exportar</Btn>
      <Btn @click.native="showPreview(form)" type="secondary" size="xs"><Icon name="eye" /> Ver</Btn>
      <Btn @click.native="openShareLink()" type="outline" size="xs"><Icon name="share-square" type="solid" /></Btn>
    </TopHeader>



    <Modal class="modal-export" :title="'Exportar ' + answersList.total_respondents +  ' respostas'" :active="exportModal" @modal-close="closeExportModal">
      <div class="export-row mt16">
        <div class="export-content">
          <div class="export-title">Exportar em Excel</div>
          <div class="export-description mt4">Ideal para usar com Microsoft Excel</div>
        </div>
        <div class="export-button">
          <Btn @click.native="exportAnswers('xlsx')" size="sm" type="outline" :loading="working.export.xlsx"><Icon name="download" /> Exportar em Excel</Btn>
        </div>
      </div>

      <div class="export-row mt16">
        <div class="export-content">
          <div class="export-title">Exportar em CSV</div>
          <div class="export-description mt4">Ideal para outros tipos de aplicações</div>
        </div>
        <div class="export-button">
          <Btn @click.native="exportAnswers('csv')" size="sm" type="outline" :loading="working.export.csv"><Icon name="download" /> Exportar em CSV</Btn>
        </div>
      </div>

      <Callout class="mt16" emoji="👉">
        <router-link :to="{name: 'ExportAnswers', slug}">Procurando uma exportação recente? Ver os arquivos exportados.</router-link>
      </Callout>      
    </Modal>


    <!-- Publish Popover -->
    <PublishLinkPopover v-if="form" ref="shareLink" :active="showShareLink" :slug="form.slug" v-on:publish-link-close="closeShareLink"/>
    
    <!-- Filters Popover -->
    <PopoverBox class="popover-filters" target="filters" :width="300" @hide="resetFilters">
      <div class="row filter-item mb16">
        
        <span class="label">
          Somente completos
          <Help width="200px"
                description="Exibe somente respondentes que completaram o formulário. <br><br>Note que perguntas adicionadas posteriormente ao envio original ainda podem aparecer sem resposta."
                link="https://respondi.helpscoutdocs.com/article/77-filtro-de-respostas-completas" />
        </span>

        <SwitchField v-model="filters.completed" :active="filters.completed" size="sm"/>
      </div>

      <Btn @click.native="filterAnswers()" size="xs" type="outline">Filtrar</Btn>
    </PopoverBox>


     <main v-if="isNew" class="new-form">
       <div class="empty">
         <img src="https://i.imgur.com/P4moFSw.png" alt="Icone">
         <h3>Este formulário ainda não tem respostas. <br>Compartilhe para começar a receber respostas.</h3>
        <Btn @click.native="openShareLink()" class="mt16" type="outline" size="sm"><Icon name="share-square" /> Compartilhar</Btn>
       </div>
     </main>

      <main v-else class="page-content">
          <Loader :loading="working.getanswers" />
          <AnswersTable v-if="layoutTable" :form="form" :data="answersList" @download-answers="exportAnswers" @delete-answer="deleteAnswer" :total="answersList.total_respondents" />
          <AnswersList v-else :data="answersList" @download-answers="exportAnswers" @delete-answer="deleteAnswer" :total="answersList.total_respondents" />
      </main>

  </div>
</template>

<script>
import Help from '../components/Help.vue'
import PopoverBox from '../components/popover/PopoverBox.vue'
import Callout from '../components/Callout.vue'
import TabsNav from '../components/TabsNav.vue'
import Modal from '../components/Modal.vue'
import PublishLinkPopover from '../components/PublishLinkPopover.vue'
import SwitchField from '../components/form/SwitchField.vue'
import AnswersList from '../components/AnswersList.vue'
import AnswersTable from '../components/AnswersTable.vue'
import Clipboard from 'clipboard';
import Icon from '@/components/Icon.vue';
import Btn from '@/components/form/Btn.vue';
import TextField from '@/components/form/TextField.vue';
import Preview from '@/components/Preview.vue';
import TopHeader from '@/components/TopHeader.vue';
import Loader from '@/components/Loader.vue';
import FileDownload from 'js-file-download';
import slugify from 'slugify';
import { FormMixin } from '@/mixins/FormMixin';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
const axios = require('axios').default;

export default {
  name: 'EditorAnswers',
  mixins: [UtilitiesMixin, FormMixin],
  components: {
    Icon,
    Preview,
    TopHeader,
    Btn,
    TextField,
    Loader, AnswersTable, AnswersList, SwitchField, PublishLinkPopover, Modal, TabsNav, Callout, PopoverBox, Help
  },

  data: function(){
    return {
      nav: 'Editor',
      answersList: null,
      currentPage: 1,
      layoutTable: false,
      showShareLink: false,
      exportModal: false,
      filters: {
        completed: false
      }
    }
  },

  computed: {
    isNew() {
      if(this.answersList?.total_respondents > 0) return false
      return true
    }
  },

  
  mounted () {
    this.setDefaultFilters();
    this.setDefaultLayout();    
    this.getAnswers()
    .then(response => {
      this.answersList = response
    })
    new Clipboard('.copy');
  },

  methods: {
    resetFilters(){
      this.filters = this.getCurrentFilters()
    },    

    filterAnswers(){
      this.$trackEvent.mixpanel("Feature: Filters", {filters: this.filters})
      this.getAnswers()
      .then(response => {
        this.answersList = response
        this.saveFiltersSettings()
      })
    },

    saveFiltersSettings(){
        let currentFilters = JSON.parse(localStorage.getItem('formFilters'))
        
        if(!currentFilters) currentFilters = {}
        currentFilters[this.form.slug] = this.filters        
        localStorage.setItem('formFilters', JSON.stringify(currentFilters))
    },

    getCurrentFilters(){
      const filters = JSON.parse(localStorage.getItem('formFilters'))
      return filters ? filters[this.slug] : null
    },

    setDefaultFilters(){
      const currentFilters = this.getCurrentFilters()
      if(currentFilters) this.filters = currentFilters      
    },

    openExportModal(){
        this.exportModal = true
      },

    closeExportModal(){this.exportModal = false},

    openShareLink(){
        this.showShareLink = true
    },
    closeShareLink(){
        this.showShareLink = false
    },

      useLayoutTable(){
        this.layoutTable = !this.layoutTable
        localStorage.answersLayoutTable = this.layoutTable
      },

      setDefaultLayout(){
        if(localStorage.answersLayoutTable === 'true') this.layoutTable = true
        else this.layoutTable = false
      },

      deleteAnswer(respondent_id){
          this.working.deleteAnswer = true;
          return axios
          .delete(process.env.VUE_APP_API_URL + 'respondent/' + respondent_id)
          .then(response => {  
              this.working.deleteAnswer = false;
              return response.data
          })            
          .catch(error => console.log(error))

      }, 

      getAnswers(page = 1){
          this.working.getanswers = true;
          return axios
          .get(process.env.VUE_APP_API_URL + 'answer/form/' + this.$route.params.slug + "?page=" + page + "&completed=" + this.filters.completed)
          .then(response => {  
            this.working.getanswers = false;
              return response.data
          })            
          .catch(error => console.log(error))

      }, 

      loadMoreAnswers(){
        return new Promise((resolve, reject) => {      
          this.getAnswers(this.currentPage + 1)
          .then(response => {
            this.answersList.respondents = this.answersList.respondents.concat(response.respondents)
            this.currentPage = this.currentPage + 1
            resolve(response)
          })
        })
      },

      exportAnswers(format){
        this.working.export[format] = true
        axios
          .post(process.env.VUE_APP_API_URL + 'answers/export/' + this.form.slug, {format: format})
          .then(response =>{
              this.closeExportModal()
              this.$router.push({name: 'ExportAnswers', slug: this.slug})
              this.working.export[format] = false
          })
          .catch(error => console.log(error))
          
          this.$trackEvent.mixpanel("Export Answers", {distinct_id: this.form.cus, form_id: this.form.slug, type: format});

      },


      formatAnwser(answer){
        if(answer.field_type != "radio") return answer.value        
        if(!this.stringIsJson(answer.value)) return answer.value
        else{
          const options = JSON.parse(answer.value)
          return options.join(", ")        
        }
      },


  }

}

</script>

<style lang="sass">
.popover-filters
  .filter-item
    justify-content: space-between
    font-size: $fzXs

.filters-count
  color: $cPrimary
  font-weight: bold

.modal-export
  .export-row
    display: flex
    justify-content: space-between
    border-bottom: 1px solid $cG2
    padding-bottom: $mgSm

    &:last-child
      border: 0
      padding-bottom: 0

    .export-title
      font-size: $fzXs

    .export-description
      font-size: $fzXxs
      color: $cG5

.new-form
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  height: calc(100% - 100px)

  h3
    font-weight: normal
    color: $cG5
    margin-top: $mgSm
    max-width: 400px

.layout-style
  display: flex
  align-items: center
  color: $cG5
  font-size: $fzXs

.editor-answers
  height: 100%
  max-height: 100%

  .page-content
    background: $cG2

.answers-header
  display: flex
  justify-content: space-between
  align-items: center

.page-content
  height: calc(100% - 60px)


</style>
