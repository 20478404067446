<template>
    <div class="ShowAnswer" :data-type="question.field_type">
        <div class="label mb8">{{questionTitleWithVariables(question.field_title)}}</div>
        <div v-if="showValue" class="value">{{value}}</div>

        <!-- LegalText -->
        <div v-if="question.field_type == 'legaltext'" class="value legaltext">
            {{formatAnswerText(question)}}
        </div>

        <!-- CPF -->
        <div v-if="question.field_type == 'cnpj'" class="value cnpj">
            {{formatAnswerText(question)}} <Btn @click.native="trackEvent('Feature: CNPJ Shortcut')" :href="'https://cnpjs.rocks/cnpj/' + question.value" layout="icon" size="sm"><Icon name='search' /></Btn>
        </div>

        <!-- CPF -->
        <div v-if="question.field_type == 'cpf'" class="value cpf">
            {{formatAnswerText(question)}}
        </div>

        <!-- Address -->
        <div v-if="question.field_type == 'address'" class="value address">
            {{formatAnswerText(question)}}
        </div>

        <!-- Phone -->
        <div v-if="question.field_type == 'phone'" class="value phone">
            {{formatAnswerText(question)}} <Btn @click.native="trackEvent('Feature: WhatsApp Shortcut')" :href="'https://api.whatsapp.com/send?phone='+ formatAnswerAsFullPhone(question.value)" layout='icon' class="whatsapp-button" size="sm"><Icon name='whatsapp' type="brand" /></Btn>
        </div>

        <!-- Date -->
        <div v-if="question.field_type == 'date'" class="value date">
            {{formatAnswerAsDate(question.value)}}
        </div>

        <!-- Image radio -->
        <div v-if="question.field_type == 'imageradio'" class="imageradio-container">
            <div v-for="item in value" :key="item.key" class="imageradio-item">
                <div class="imageradio-img-container"><img v-if="item.image" :src="$bucket + item.image.path" class="imageradio-img" alt=""></div>
                <div class="imageradio-label mt8">{{item.label}}</div>
            </div>
        </div>

        <!-- Checkbox -->
        <div v-if="multiple && question.field_type == 'radio'" class="value radio">
            <div v-for="(item, index) in value" :key="index">- {{item}}</div>
        </div>

        <!-- Upload -->
        <div v-if="question.field_type == 'upload'" class="value upload">
            <div v-for="file in value" :key="file.path" class="item">
                <a v-if="isImage(file)" target="_blank" :href="$bucket + file.path" class="file-container image-preview mb8 mr8">
                    <img :src="$bucket + file.path" :alt="file.filename">
                </a> 
                <a v-else :href="$bucket + file.path" target="_blank" download class="file-container file-preview mb8 mr8">
                    <div class="file-icon mr8"><Icon name="file-download" /></div>
                    <div class="file-name">{{file.filename}}</div>
                </a>
              
            </div>
        </div>

        
        <!-- Rating -->
        <div v-if="question.field_type =='rating'" class="rating">
            <Icon name="star" :type="setRating(1)" class="icon" />
            <Icon name="star" :type="setRating(2)" class="icon" />
            <Icon name="star" :type="setRating(3)" class="icon" />
            <Icon name="star" :type="setRating(4)" class="icon" />
            <Icon name="star" :type="setRating(5)" class="icon" />
            <Icon name="star" :type="setRating(6)" class="icon" />
            <Icon name="star" :type="setRating(7)" class="icon" />
            <Icon name="star" :type="setRating(8)" class="icon" />
            <Icon name="star" :type="setRating(9)" class="icon" />
            <Icon name="star" :type="setRating(10)" class="icon" />
            <div class="value value-rating">{{value}}</div>
        </div>

    </div>
</template>

<script>
import Btn from '../form/Btn.vue'
import Icon from '../Icon.vue'
import {UtilitiesMixin} from '@/mixins/UtilitiesMixin'
    export default {
        name: "ShowAnswer",
        components: { Icon, Btn },
        mixins: [UtilitiesMixin],
        props: {
            question: {
                type: Object,
                default: null
            },
        },

        data() {
            return {
                multiple: false                
            }
        },

        computed: {
            value() {
                if(this.stringIsJson(this.question.value)){
                    if(this.question.field_type != 'upload') this.multiple = true
                    return JSON.parse(this.question.value)
                } 
                else return this.question.value                
            },  
            showValue(){
                const remove = ["cnpj", "cpf", "date", "rating", "upload", "address", "phone", "imageradio", "legaltext"]
                if(remove.includes(this.question.field_type)) return false
                if(this.multiple) return false
                return true
            }          
        },

        methods: {

            setRating(position) {
                if(position > this.question.value) return 'regular'
                else return 'solid'                
            },

            isImage(file){
                return file['type'].includes('image')
            },

            trackEvent(eventName){
                const form = this.$parent.$parent.form
                this.$trackEvent.mixpanel(eventName, {distinct_id: form.cus, form_id: form.slug});
            },
        },
    
    }
</script>

<style lang="sass" scoped>
.whatsapp-button::v-deep .btn
    color: #25D366
    font-size: $fzSm

    &:hover
        color: #25D366
        background-color: rgba(#25D366, .15)

.label
    font-size: $fzXs
    color: $cBrand

.value
    font-size: $fzSm


[data-type='rating']
    .rating
        font-size: $fzLg
        display: flex
        gap: $mgXs
        align-items: center
    
    .icon
        color: $cRating
    
    .value-rating
        font-size: $fzXs
        color: $cG5


[data-type='upload']
    .upload
        display: flex
        flex-wrap: wrap
        // background: red
        max-width: 100%
        width: 100%
        // overflow: auto
        // padding-bottom: $mgSm
    
    .item
        margin: 0 $mgXxs 0 $mgXxs
        display: flex

        .file-container
            width: 150px
            height: 150px
            border-radius: 5px
            color: $cPrimary
            font-size: $fzXs
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            text-align: center
            background: $cG1
            border: 1px solid $cG2            


            &:hover
                border: 2px solid $cPrimary
                background: rgba($cPrimary, .15)


        .image-preview
            overflow: hidden

            img
                max-width: 90%
                max-height: 90%

        .file-preview

            .file-icon
                font-size: $fzXl
                margin-bottom: $mgXs



        .file-row
            display: inline-flex
            font-size: $fzXs
            color: $cG4
            padding: $mgXs $mgXs
            border: 1px solid $cG4
            border-radius: 4px

            &:hover
                cursor: pointer
                @include glassStyle($cPrimary)


[data-type='imageradio']
    .imageradio-container
        display: flex
        flex-wrap: wrap

        .imageradio-item
            flex: 0 0 140px
            background: $cG1
            padding: $mgSm
            border-radius: 4px
            margin: 0 $mgSm $mgSm 0
            
            &:hover
                outline: 2px solid $cPrimary

            .imageradio-img-container
                display: flex
                align-items: center
                justify-content: center
                height: 100px

            .imageradio-img
                object-fit: contain
                max-height: 100%

            .imageradio-label
                font-size: $fzXxs
                line-height: 120%
                text-align: center





    


</style>