<template>
    <div v-on-clickaway="closeOptions" comp="select-row-status-comp" :data-active="active">
        <div class="current-option" @click="openOptions">
            <div v-if="!currentValue" class="option-circle option-default"><Icon name="tag" /></div>
            <div v-else class="option-circle" :style="{backgroundColor: currentValue}"></div>
        </div>
        <div class="options-container">
            <div v-for="option in options" :key="option" @click="setValue(option)" class="option">
                <div class="option-circle" :style="{backgroundColor: option}"></div>                
            </div>
            <div @click="deleteValue" class="option option-remove">
                <div class="option-circle"><Icon name="trash-alt" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../Icon.vue'
import ColorPickerField2 from './ColorPickerField2.vue'
import { AnswersMixin } from '@/mixins/AnswersMixin'
import { mixin as clickaway } from 'vue-clickaway';


    export default {

    components: { ColorPickerField2, Icon },
        name: "SelectRowStatus",  
        mixins: [AnswersMixin, clickaway],
        props: {
            respondent: String,
            value: {
                type: Object,
                default: function(){
                    return {name: "status", value: null}
                }
            },
        },    

        mounted () {
            this.currentValue = this.value.value
            this.currentStatus = this.value
        },

        data() {
            return {
                currentValue: null,
                currentStatus: null,
                active: false,
                options: ['#d32f2f', '#E91E63', '#673AB7', '#2979FF', '#039BE5', '#00ACC1', '#00897B', '#7CB342', '#FDD835', '#FB8C00', "#FF5722", "#795548", '#263238']
            }
        },

        methods: {
            setValue(value) {
                this.currentValue = value
                this.updateValue()
                this.closeOptions()
            },

            closeOptions(){
                this.active = false
            },
            
            openOptions(){
                this.active = true
            },

            updateValue(){
                const data = this.currentStatus
                data.value = this.currentValue
                this.updateCustomField(data, this.respondent)
                .then(response => {
                    this.currentStatus = response.data
                })
            },

            deleteValue(){                
                if(!this.currentValue){
                    this.closeOptions()
                    return 
                } 

                this.currentValue = null
                this.deleteCustomField(this.currentStatus.id)
                .then(response => {
                    this.currentStatus =  {name: "status", value: null}                    
                })
                this.closeOptions()
            }
        },
    }
</script>

<style lang="sass" scoped>

.select-row-status-comp
    position: relative


.option-remove
    .option-circle
        justify-content: center
        align-items: center
        display: flex
        font-size: $fzXxs


.option-circle
    width: 15px
    height: 15px
    display: block
    border-radius: 15px
    display: flex
    align-items: center
    justify-content: center

    &.option-default
        // background: $cG2
        color: $cG3
        font-size: $fzXxs   

    &:hover
        cursor: pointer
        opacity: .8




.options-container
    gap: 6px
    display: none
    flex-wrap: wrap
    padding: $mgSm
    border: 1px solid $cG3    
    width: 180px
    border-radius: 4px
    position: absolute
    top: -60px
    left: 15px
    background: #fff

    [data-active="true"] &
        display: flex

</style>