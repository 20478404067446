<template>
    <nav class="tabs-nav-comp">
        <div :data-active="active == 0" class="item">Exportar</div>
        <div class="item">Exportações recentes</div>
    </nav>
</template>

<script>
    export default {
        name: "TabsNav",
        data() {
            return {
                active: 0
            }
        },
    }
</script>

<style lang="sass" scoped>
.tabs-nav-comp
    display: flex
    font-size: $fzXs

    .item
        padding: $mgXs
        color: $cG5
        cursor: pointer

        &:hover
            background: rgba($cPrimary, .1)
            color: $cPrimary

        &[data-active="true"]
            font-weight: bold
            color: $cBlack
            cursor: default            
            pointer-events: none


</style>