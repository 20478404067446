<script>
    // :::: Documentation: https://github.com/euvl/vue-js-popover

    export default {
        name: "PopoverBox",

        props: {
            title: String,
            target: String,
            width: Number,
        },

    }
</script>

<template>
    <popover :name="target" :width="width" class="popover-box-comp" @hide="$emit('hide')">
        <div v-if="title" class="header">{{title}}</div>
        <div class="body">
            <slot />
        </div>
    </popover>
</template>


<style lang="sass" scoped>
.popover-box-comp

    .header
        font-size: $fzXs
        color: $cG5
        padding: $mgSm
        border-bottom: 1px solid $cG2

    .body
        padding: $mgSm



</style>