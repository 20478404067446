<template>
    <section v-if="data" class="answers-list-container">
        <div class="respondent-container">
            <div class="respondents-header">
                <div class="total-respondents">
                    <span v-if="data.total_respondents > 0">{{data.total_respondents}} respostas.</span>
                    <span v-else>Ainda não tem respostas.</span>
                </div>
            </div>

            <ul class="respondents-list" ref="respondentsList">
                <li @click="setCurrent(index)" :data-active="currentRespondent(index)" v-for="(respondent, index) in data.respondents" :key="index" class="respondent-item">
                        <span class="index mr8">{{data.total_respondents - index}}.</span>
                        <span class="first">{{formatAnswerText(respondent.answers[0])}}</span>
                </li>                
                <div class="load-more">
                    <Btn type="outline" v-if="data.respondents.length != data.total_respondents" @click.native="$parent.loadMoreAnswers()">Carregar mais respostas</Btn>
                    <p v-if="data.respondents.length == data.total_respondents && data.total_respondents > 25"><Icon name="check" /></p>
                </div>
            </ul>

        </div>

        <section v-if="data.respondents && data.total_respondents > 0 && current != null" class="respondent-answers">
            <div class="questions-container">
                <div class="form-header">
                    <div class="data">
                        <div class="label mb4">Data de início: <span>{{formatDate(data.respondents[current].created_at)}}</span></div>
                        <div class="identificador">Identificador: <span>{{data.respondents[current].respondent_id}}</span></div>
                    </div>
                    <div class="actions">
                        <div v-if="!data.respondents[current].completed_at" class="complete-status mr8">Incompleto</div>
                        <div v-if="data.respondents[current].total_score" class="scoreBlock mr8">{{data.respondents[current].total_score}} <span class="score-label">Pontos</span></div>
                        <Btn @click.native="prevAnswer" layout="icon" size="sm"><Icon name="arrow-left" /></Btn>
                        <Btn @click.native="nextAnswer" layout="icon" size="sm"><Icon name="arrow-right" /></Btn>
                    </div>
                </div>
                <div class="questions-list" ref="questionsList">
                    <div v-for="(question) in data.respondents[current].answers" :key="question.field_slug" class="question">
                        <ShowAnswer :question="question" />
                    </div>

                    <div v-if="showUtms" class="respondent-utms">
                        <div class="utm-item"><strong class="title">Source:</strong> <span class="value">{{data.respondents[current].utm_source ? data.respondents[current].utm_source : "Não capturado"}}</span></div>
                        <div class="utm-item"><strong class="title">Medium:</strong> <span class="value">{{data.respondents[current].utm_medium ? data.respondents[current].utm_medium : "Não capturado"}}</span></div>
                        <div class="utm-item"><strong class="title">Campaign:</strong> <span class="value">{{data.respondents[current].utm_campaign ? data.respondents[current].utm_campaign : "Não capturado"}}</span></div>
                        <div class="utm-item"><strong class="title">Term:</strong> <span class="value">{{data.respondents[current].utm_term ? data.respondents[current].utm_term : "Não capturado"}}</span></div>
                        <div class="utm-item"><strong class="title">Content:</strong> <span class="value">{{data.respondents[current].utm_content ? data.respondents[current].utm_content : "Não capturado"}}</span></div>
                    </div>                    

                    <div class="form-footer">
                        <Btn layout="icon" size="sm" @click.native="backToTop"><Icon name="arrow-up" /> Voltar ao topo.</Btn>
                        <Btn v-if="!deleteCurrent" layout="remove" size="sm" @click.native="deleteCurrent = true">Remover</Btn>
                        <div v-else class="answer-delete-container">
                            <div class="confirmation">
                                Apagar essa resposta? <Btn @click.native="deleteAnswer(data.respondents[current].respondent_id)" type="danger" size="xs">Sim</Btn> <Btn @click.native="deleteCurrent = false" type="outline" size="xs">Não</Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </section>
</template>

<script>
import Btn from './form/Btn.vue'
    import ShowAnswer from './answers/ShowAnswer.vue'
    import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
    const axios = require('axios').default;

    export default {
        components: { ShowAnswer, Btn },
        mixins: [UtilitiesMixin],
        name: "AnswersList",
        props: {
            data: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                current: 0,
                deleteCurrent: false,
                working: {
                    export: false
                }

            }
        },

        computed: {
            showUtms() {
                return this.$parent.form.options.utms.active
            }
        },

        methods: {
            deleteAnswer(respondent_id){
                const deletedIndex = this.current
                this.$emit("delete-answer", respondent_id)
                this.data.respondents.splice(this.current, 1)                
                if(deletedIndex == this.data.respondents?.length && deletedIndex == 0) this.current = null
                if(deletedIndex == this.data.respondents?.length) this.current = this.data.respondents?.length - 1
                this.data.total_respondents = this.data.respondents?.length
                this.deleteCurrent = false
            },

            isInViewport(element) {
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },
            setCurrent(index) {
                this.current = index;                
            },

            prevAnswer(){
                if(this.current > 0) this.current = this.current -1
                this.setRespondentPosition()
            },
            nextAnswer(){
                if((this.current + 1) == this.data.total_respondents) return null
                if(this.current == (this.data.respondents?.length - 1)){
                    this.$parent.loadMoreAnswers()
                    .then(response => {
                        if(this.current < (this.data.respondents?.length - 1)) this.current = this.current +1
                        this.setRespondentPosition()
                    })

                }

                else{
                    if(this.current < (this.data.respondents?.length - 1)) this.current = this.current +1
                    this.setRespondentPosition()
                }
            },

            setRespondentPosition(){
                const respondent = document.querySelectorAll('.respondent-item')
                this.$refs.respondentsList.scrollTop = respondent[this.current].offsetTop
            },

            currentRespondent(index){
                if(index === this.current) return true
                return false
            },

            backToTop(){
                var myDiv = this.$refs.questionsList
                myDiv.scrollTop = 0;
            },

            downloadAnswers(){
                this.$emit('download-answers')                
            },

        },
    }
</script>

<style lang="sass" scoped>
.answers-list-container    
    width: 100%
    height: 100%
    display: flex  
    margin: auto  
    background: $cG2

    .load-more
        width: 100%
        padding: $mgSm 0 $mg 0
        display: flex
        justify-content: center

    .respondent-container
        display: flex
        flex-direction: column
        font-size: $fzXs
        background: #fff
        width: 100%
        max-width: 400px
        min-width: 250px
        height: 100%
        

    .respondents-header
        padding: $mgSm
        font-size: $fzMd
        border-bottom: 4px solid $cG2        


    .respondents-list
        height: 100%
        max-height: 100%
        overflow: auto
        scroll-behavior: smooth
        position: relative
        scrollbar-color: $cG3 $cG1 //Fix for fireFox

        &::-webkit-scrollbar 
            width: 10px
            // background: blue

        &::-webkit-scrollbar-track
            background: $cG1
        
        &::-webkit-scrollbar-thumb 
            background-color: $cG3
            border-radius: 6px;


    .respondent-item
        padding: $mgSm $mgSm        
        cursor: pointer
        border-bottom: 1px solid $cG2
        display: flex
        

        &:hover
            background: $cG1

        &[data-active="true"]
            color: $cPrimary
            font-weight: bold

        .time
            margin-left: auto
            font-size: $fzXxs
            color: $cG3

        

    .respondent-answers    
        display: flex
        flex: auto
        height: 100%
        justify-content: center
        overflow: auto
        padding-top: $mgLg
        padding-left: $mgSm
        padding-right: $mgSm

        .questions-container
            box-shadow: 0 0 30px rgba(0,0,0, .1)
            border-radius: 7px
            background: #fff
            width: 100%
            max-width: 700px            
            border-top: 6px solid $cBrand
            height: 100%
            display: flex
            flex-direction: column

            .questions-list
                overflow: auto
                //margin-top: auto
                flex: auto
                scroll-behavior: smooth;
                //height: calc(100% - #{$mgLg})

            .question
                padding: ($mgSm * 1.3)
                border-bottom: 1px solid $cG2


            .respondent-utms
                padding: $mgSm
                font-size: $fzXs
                background: $cG1
                color: $cG4
                font-size: $fzXxs

                .utm-item
                    display: flex
                    margin-bottom: $mgXs

                .title
                    width: 100px

                .value
                    margin-left: $mgXs

            .form-footer
                display: flex
                justify-content: space-between
                padding: $mg $mgSm
                font-size: $fzXs
                color: $cG4

                .answer-delete-container

        .form-header
            padding: $mgSm
            font-size: $fzXxs
            color: $cG5
            display: flex
            justify-content: space-between

            .actions
                display: flex
                justify-content: flex-end
                width: 100px

    .scoreBlock
        +glassStyle($cPrimary)
        padding: $mgXxs $mgXs
        font-size: $fzMd
        display: flex
        align-items: center
        text-align: center
        border-radius: 4px

        .score-label
            font-weight: bold
            font-size: $fzXxs
            margin-left: $mgXs

    .complete-status
        +glassStyle($cWarning)
        padding: $mgXxs $mgXs
        font-size: $fzXs
        display: flex
        align-items: center
        text-align: center
        border-radius: 4px



</style>